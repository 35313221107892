import { FaroIconButton } from "@components/common/faro-icon-button";
import { FaroMenu, FaroMenuItem } from "@faro-lotv/flat-ui";
import { useRef, useState } from "react";
import VerticalDotsIcon from "@assets/icons/new/vertical-dots_28px.svg?react";
import { useMembersUtils } from "@hooks/use-member-utils";
import { MemberTypes } from "@custom-types/member-types";
import { Typography } from "@mui/material";
import { UserDisplay } from "@components/common/user-display";
import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  TableType,
  TeamMemberEvents,
  WorkspaceMemberEvents,
} from "@utils/track-event/track-event-list";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { useCoreApiClient } from "@api/use-core-api-client";
import { removeMembersFromTeam } from "@store/teams/teams-slice-thunk";
import { fetchingTeamsFlagsSelector } from "@store/teams/teams-selector";
import { RemoveMemberSuccessDescription } from "@components/remove-member-success-description";
import { useToast } from "@hooks/use-toast";
import { BaseTeamProps } from "@custom-types/teams-types";
import { removeMemberFromCompany } from "@store/members/members-slice";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { selectedSdbCompanyNameSelector } from "@store/sdb-company/sdb-company-selector";

interface Props extends BaseCompanyIdProps, BaseTeamProps {
  /** The member of a team */
  member: MemberTypes;
}

/** Used to determine from where a member should be removed */
enum RemoveDialogEntity {
  /** Used to determine if the member should be removed from team */
  team = `${TEAM_DISPLAY_NAME}`,

  /** Used to determine if the member should be removed from workspace */
  workspace = "workspace",
}

/** Renders actions dropdown for team members */
export function TeamMemberActions({
  member,
  companyId,
  team,
}: Props): JSX.Element {
  const { openProfilePage } = useMembersUtils();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { isRemovingTeamMember } = useAppSelector(fetchingTeamsFlagsSelector);
  const { isRemovingCompanyMember } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const companyName = useAppSelector(selectedSdbCompanyNameSelector);
  const { showToast } = useToast();

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [removeDialogEntity, setRemoveDialogEntity] =
    useState<RemoveDialogEntity | null>(null);

  function onOpenProfile(): void {
    openProfilePage({
      userIdentity: member.identity,
      source: "team members table",
    });
  }

  /** Determines remove member action */
  async function removeMember(): Promise<void> {
    switch (removeDialogEntity) {
      case RemoveDialogEntity.team:
        removeMemberFromTeam();
        break;

      case RemoveDialogEntity.workspace:
        removeCompanyMember();
        break;

      default:
        break;
    }
  }

  /** Handles removing a team member */
  async function removeMemberFromTeam(): Promise<void> {
    trackEvent({
      name: TeamMemberEvents.removeMember,
      props: { dataType: TableType.teamMembers },
    });

    try {
      await dispatch(
        removeMembersFromTeam({
          coreApiClient,
          companyId,
          teamId: team.id,
          memberIds: [member.identity],
        })
      ).unwrap();

      showToast({
        message: `Member removed from ${TEAM_DISPLAY_NAME}`,
        description: (
          <RemoveMemberSuccessDescription
            member={member}
            subjectType="team"
            entityName={team.name}
          />
        ),
        type: "success",
      });
    } finally {
      setRemoveDialogEntity(null);
    }
  }

  /** Handles removing a company member */
  async function removeCompanyMember(): Promise<void> {
    trackEvent({
      name: WorkspaceMemberEvents.removeMember,
      props: { dataType: TableType.teamMembers },
    });

    try {
      await dispatch(
        removeMemberFromCompany({ coreApiClient, companyId, member })
      ).unwrap();

      showToast({
        message: "Member removed from workspace",
        description: (
          <RemoveMemberSuccessDescription
            member={member}
            subjectType="workspace"
            entityName={companyName ?? ""}
          />
        ),
        type: "success",
      });
    } finally {
      setRemoveDialogEntity(null);
    }
  }

  return (
    <>
      <FaroIconButton
        aria-label="more"
        aria-haspopup="true"
        component={VerticalDotsIcon}
        onClick={() => setIsMenuOpen(true)}
        iconButtonProps={{ ref: anchorRef }}
      />
      <FaroMenu
        open={isMenuOpen}
        anchorEl={anchorRef.current}
        onClose={() => setIsMenuOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FaroMenuItem label="Profile" onClick={onOpenProfile} />
        <FaroMenuItem
          label="Remove from group"
          onClick={() => setRemoveDialogEntity(RemoveDialogEntity.team)}
        />
        <FaroMenuItem
          label="Remove from workspace"
          onClick={() => setRemoveDialogEntity(RemoveDialogEntity.workspace)}
        />
      </FaroMenu>

      {/* Dialog with the details of the member to be removed */}
      <FaroDialog
        title={`Remove from ${removeDialogEntity}`}
        confirmText="Remove"
        open={removeDialogEntity !== null}
        onConfirm={removeMember}
        onClose={() => setRemoveDialogEntity(null)}
        isConfirmLoading={isRemovingTeamMember || isRemovingCompanyMember}
      >
        <Typography
          sx={{
            fontSize: "14px",
            marginTop: "0px",
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          When removing member from {removeDialogEntity} they will no longer be
          able to access this {removeDialogEntity}. You can always invite this
          member again.
        </Typography>

        <UserDisplay member={member} />
      </FaroDialog>
    </>
  );
}
